import React from 'react'

class GlobalNav extends React.Component {
  constructor(props) {
    super(props);

    this.root = React.createRef();
  }

  componentDidMount() {
    if (window.location.hash !== '') {
        let el = document.querySelector(`[id="${window.location.hash.substr(1)}"]`);

        if (el)
          window.scrollTo(0, el.offsetTop);
    }

    window.addEventListener('scroll', this.onScroll.bind(this));
    this.onScroll();
  }

  onScroll() {
    let top = document.documentElement.scrollTop || document.body.scrollTop,
        allSections = document.querySelectorAll('.fxui-root > div[id]');

    allSections.forEach((section) => {
      document.querySelector(`#li-${section.id}`).classList.remove('slds-is-active');
      document.querySelector(`#li-${section.id} .nav-link`).classList.remove('active');

      if (top >= section.offsetTop - 16 && top <= section.offsetTop + section.offsetHeight - 16) {
        document.querySelector(`#li-${section.id}`).classList.add('slds-is-active');
        document.querySelector(`#li-${section.id} .nav-link`).classList.add('active');
      }
    });
  }

  render() {
    let globalNav = {},
        globalNavSort = [];

    this.props.data.allMarkdownRemark.edges.forEach(({ node }) => {
      if (globalNavSort.indexOf(node.fields.leaf) === -1)
        globalNavSort.push(node.fields.leaf);

      if (!globalNav[node.fields.leaf])
        globalNav[node.fields.leaf] = {
          leaf: node.frontmatter.leaf === 'none'? '' : <h3 className="slds-nav-vertical__title pl-2 h5">{node.frontmatter.leaf}</h3>,
          children: []
        };

      globalNav[node.fields.leaf].children.push((
        <li className="slds-nav-vertical__item nav-item" key={node.fields.slug} id={'li-' + node.fields.slug}>
          <a href={'#' + node.fields.slug} className="slds-nav-vertical__action nav-link">{node.frontmatter.title}</a>
        </li>
      ));
    });

    return (
      <nav ref={this.root} role="main" className="slds-nav-vertical slds-theme_default slds-box card fxui-global-nav bg-white">
        {globalNavSort.map((navId, i) => {
          return (
            <div className="slds-nav-vertical__section" key={'section' + i}>
              {globalNav[navId].leaf}
              <ul className="nav nav-pills flex-column mx-2">
                {globalNav[navId].children.map((navChild) => {
                  return navChild;
                })}
              </ul>
            </div>
          );
        })}
      </nav>
    );
  }
}

export default GlobalNav;
