// Builds a color grid into a `<div id="fox-ui-color-grid">` element

/**
// FOX
$color-black-resin: #232222;
$color-fox-pop-blue: #004c97;
$color-fox-red: #d2232a;
$color-fox-gray: #e5e5e5;
$color-fox-dark-blue: #00263e;
$color-fox-dark-red: #830b0d;
$color-fox-light-blue: #008bcd;

// FOX AI:
$color-primary-color-fill-normal: #00578b;
$color-primary-color-fill-dark-logo: #004269;
$color-primary-color-fill-light: #0077bd;
$color-secondary-color-fill-normal: #2ea4e1;
$color-tertiary-color-fill-normal: #f98800;
$color-tertiary-color-fill-dark: #fa6400;
$color-numbers-fill-normal-money-value: #454545;
$color-numbers-fill-normal-percentage-value: #00c155;
$color-chart-guide-sports: #ef9900;
$color-chart-guide-news: #c70052;
$color-chart-guide-entertainment: #3f8acd;
*/

const allColors = [
  { divider: 'FOX Brand colors' },
  { label: 'color-black-resin', hex: '#232222' },
  { label: 'color-fox-pop-blue', hex: '#004c97' },
  { label: 'color-fox-red', hex: '#d2232a' },
  { label: 'color-fox-gray', hex: '#e5e5e5' },
  { spacer: true },
  { label: 'color-fox-dark-blue', hex: '#00263e' },
  { label: 'color-fox-light-blue', hex: '#008bcd' },
  { label: 'color-fox-dark-red', hex: '#830b0d' },
  { divider: 'SLDS Colors' },
  { label: 'default', hex: '#080707' },
  { label: 'inverse', hex: '#00263e' },
  { label: 'weak', hex: '#3e3e3c' },
  { label: 'info', hex: '#706e6b' },
  { spacer: true },
  { label: 'success', hex: '#027e46' },
  { label: 'warning', hex: '#ffb75d' },
  { label: 'error', hex: '#d2232a' },
  { spacer: true },
  { spacer: true },
  { label: 'theme success', hex: '#04844b' },
  { label: 'theme warning', hex: '#ff9e2c' },
  { label: 'theme error', hex: '#c23934' }
  // { divider: 'FOX AI' },
  // { label: 'color-primary-color-fill-normal', hex: '#00578b' },
  // { label: 'color-primary-color-fill-dark-logo', hex: '#004269' },
  // { label: 'color-primary-color-fill-light', hex: '#0077bd' },
  // { label: 'color-secondary-color-fill-normal', hex: '#2ea4e1' },
  // { label: 'color-chart-guide-entertainment', hex: '#3f8acd' },
  // { label: 'color-tertiary-color-fill-normal', hex: '#f98800' },
  // { label: 'color-tertiary-color-fill-dark', hex: '#fa6400' },
  // { label: 'color-chart-guide-sports', hex: '#ef9900' },
  // { label: 'color-numbers-fill-normal-money-value', hex: '#454545' },
  // { label: 'color-numbers-fill-normal-percentage-value', hex: '#00c155' },
  // { label: 'color-chart-guide-news', hex: '#c70052' },
];

const htmlColors = [];

allColors.forEach((color) => {
  if (color.divider) {
    htmlColors.push(`<div class="slds-col slds-size_5-of-5 fxui-color-grid-divider col-12 w-100" style="padding: 0;"><h3 class="slds-text-heading_medium slds-m-top_medium slds-m-bottom_x-small">${color.divider}</h3></div>`);
    return true;
  }
  if (color.spacer) {
    htmlColors.push(`<div class="slds-col slds-size_1-of-5 fxui-color-grid-item col">&nbsp;</div>`);
    return true;
  }

  let html = `<div class="slds-col slds-size_1-of-5 fxui-color-grid-item col" style="background-color: ${color.hex};"><span>${color.label}:<br><strong>${color.hex}</strong></span></div>`;

  htmlColors.push(html);
});

if (typeof window !== 'undefined' && /^\/bootstrap/.test(window.location.pathname)) {
  htmlColors.unshift(`<div class="row w-100">`);
  htmlColors.push(`</div>`);
}

module.exports.init = () => {
  let el = document.getElementById('fox-ui-color-grid');

  if (!el)
    return;

  el.innerHTML = htmlColors.join('');
}
