import React from 'react';

import classNames from 'classnames';

import FOXUiColorGrid from './utils/fox-ui-color-grid';
import SelfDoc from './utils/self-doc';

let setTypography = (className, elements) => {
  elements.forEach((el) => {
    el.classList.add(...className.split(' '));
  });
};

class ContentBlock extends React.Component {
  constructor(props) {
    super(props);

    this.root = React.createRef();
  }

  componentDidMount() {
    this.setStyles();

    FOXUiColorGrid.init();
    SelfDoc.init(this.root.current);
  }

  componentDidUpdate() {
    this.setStyles();
    SelfDoc.init(this.root.current);
  }

  setStyles() {
    setTypography('slds-text-heading_large h1',  this.root.current.querySelectorAll('.fxui-content > h2'));
    setTypography('slds-text-heading_medium slds-m-top_large h2', this.root.current.querySelectorAll('.fxui-content > h3'));
    setTypography('slds-text-heading_small slds-m-top_large h3',  this.root.current.querySelectorAll('.fxui-content > h4'));
  }

  render() {
    return (
      <div ref={this.root} id={this.props.node.fields.slug}>
        <div className={classNames('slds-box', 'slds-theme_default', 'card', 'bg-white', 'fxui-content-wrapper', 'p-3', this.props.node.frontmatter.contentClass)}>
          <div className="fxui-content" dangerouslySetInnerHTML={{__html: this.props.node.html}}></div>
        </div>
      </div>
    );
  }
};

export default ContentBlock;
