// Takes markdown code blocks and turns them into code/preview blocks

let docId = 1;

const getToggle = () => {
  docId++;
  let div = document.createElement('div');

  div.classList.add('slds-form-element', 'self-doc-toggle');
  div.innerHTML = `<label class="slds-checkbox_toggle slds-grid">
    <span class="slds-form-element__label slds-m-bottom_none">Preview</span>
    <input type="checkbox" name="checkbox-toggle-${docId}" value="checkbox-toggle-${docId}" checked />
    <span id="checkbox-toggle-${docId}" class="slds-checkbox_faux_container">
    <span class="slds-checkbox_faux"></span>
    </span>
    </label>`;

  return div;
};

exports.init = (contentBlock) => {
  let blocks = contentBlock.querySelectorAll('.self-doc .gatsby-highlight');

  blocks.forEach((block) => {
    if (block.classList.contains('is-self-doc'))
      return;

    let showingPreview = false,

        html = document.createElement('div'),
        toggle = getToggle(),
        pre = block.querySelector('pre');

    block.classList.add('is-self-doc');

    html.classList.add('self-doc-preview');
    html.innerHTML = pre.innerText;
    html.style.display = `none`;

    toggle.querySelector('input').addEventListener('change', (e) => {
      showingPreview = !showingPreview;

      html.style.display = showingPreview? `block` : `none`;
      pre.style.display = showingPreview? `none` : `block`;
    });

    toggle.querySelector('input').dispatchEvent(new Event('change'));

    block.appendChild(html);
    block.insertBefore(toggle, pre);
  })
};
